import { getI18n } from 'react-i18next';
import { z } from 'zod';

import { DashboardType } from 'Types/index';

const technologyFormSchema = z
  .object({
    name: z
      .string({
        required_error: getI18n().t('messages.thisFieldIsRequired')
      })
      .trim()
      .min(1, getI18n().t('messages.theMinimumNumberOfCharactersIs', { length: 1 }))
      .max(50, getI18n().t('messages.theMaximumNumberOfCharactersIs', { length: 50 })),
    isLineMode: z.boolean(),
    standardFTQ: z
      .number()
      .min(1, { message: getI18n().t('messages.valueMustBeGreaterThan', { value: 1 }) })
      .max(3600, { message: getI18n().t('messages.valueMustBeLessThan', { value: 3600 }) })
      .or(z.literal(''))
      .optional(),
    standardBottleneckTakt: z
      .number()
      .min(1, { message: getI18n().t('messages.valueMustBeGreaterThan', { value: 1 }) })
      .max(3600, { message: getI18n().t('messages.valueMustBeLessThan', { value: 3600 }) })
      .or(z.literal(''))
      .optional(),
    components: z
      .object({
        id: z.number({ required_error: 'required' }).optional(),
        variantId: z
          .string({ required_error: getI18n().t('messages.thisFieldIsRequired') })
          .min(1, getI18n().t('messages.thisFieldIsRequired')),
        amount: z.number().min(1).max(31556926),
        machines: z
          .object({ id: z.string(), dashboardType: z.custom<DashboardType>() })
          .array()
          .min(1, getI18n().t('messages.selectAtLeastX', { min: 1 })),
        tempId: z.string().optional()
      })
      .array()
      .min(1, getI18n().t('messages.thisFieldIsRequired'))
      .superRefine((componenets, { addIssue }) => {
        const hasDuplicateComponentsMap = new Map<string, boolean>();
        componenets.forEach(({ variantId }) => {
          if (hasDuplicateComponentsMap.has(variantId)) {
            hasDuplicateComponentsMap.set(variantId, true);
          } else {
            hasDuplicateComponentsMap.set(variantId, false);
          }
        });

        componenets.forEach(({ variantId, machines }, index) => {
          if (variantId && hasDuplicateComponentsMap.get(variantId)) {
            addIssue({
              code: 'custom',
              message: getI18n().t('messages.duplicatedComponents'),
              path: [index, 'variantId']
            });
          }

          if (machines.length > 1 && [...new Set(machines.map(x => x.dashboardType))].length > 1) {
            addIssue({
              code: 'custom',
              message: getI18n().t('messages.selectMachinesWithTheSameDashboardType'),
              path: [index, 'machines']
            });
          }
        });
      })
  })
  .refine((data) => (data.isLineMode ? data.standardFTQ : true), {
    message: getI18n().t('messages.thisFieldIsRequired'),
    path: ['standardFTQ']
  })
  .refine((data) => (data.isLineMode ? data.standardBottleneckTakt : true), {
    message: getI18n().t('messages.thisFieldIsRequired'),
    path: ['standardBottleneckTakt']
  });

export const getTechnologFormSchema = ({
  variantsWithoutMachinesIds = []
}: {
  variantsWithoutMachinesIds: string[];
}) => {
  return technologyFormSchema.superRefine(({ components }, { addIssue }) => {
    components.forEach(({ variantId }, index) => {
      if (variantsWithoutMachinesIds.includes(variantId)) {
        addIssue({
          code: 'custom',
          message: getI18n().t('messages.componentHasNotWorkstations'),
          path: ['components', index, 'variantId']
        });
      }
    });
  });
};

export type TechnologyFormValues = z.infer<typeof technologyFormSchema>;
