import { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';

import { FieldArrayItem, FormManualNumericControl, FormMultiSelectVirtualized } from 'Components/form-components';
import SelectVirtualized from 'Components/SelectVirtualized';

import { VariantWithMachines } from './TechnologyForm';
import { TechnologyFormValues } from './validation';

interface TechnologyComponentProps {
  index: number;
  variantsWithMachines: VariantWithMachines[];
  component: TechnologyFormValues['components'][0];
  onRemoveClick?: () => void;
  defaultExpanded?: boolean;
  showRemoveButton?: boolean;
}

export const TechnologyComponent: FC<TechnologyComponentProps> = ({
  index,
  variantsWithMachines,
  component,
  onRemoveClick,
  defaultExpanded = false,
  showRemoveButton = true
}) => {
  const { t } = useTranslation();
  const { control, trigger, watch, setValue, clearErrors } = useFormContext<TechnologyFormValues>();
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const variantWithMachinesOptions = variantsWithMachines.map(({ id, name, isActive, isDeleted }) => {
    if (isDeleted) return { id, name: `${name} (${t('labels.deleted', { context: 'component' })})` };

    if (!isActive) return { id, name: `${name} (${t('labels.inactive', { context: 'component' })})` };

    return { id, name };
  });

  const variantId = watch(`components.${index}.variantId`);
  const variantMachines = variantsWithMachines.find((v) => v.id === variantId)?.machines ?? [];
  const componentMachines = watch(`components.${index}.machines`);
  const componentMachineIds = componentMachines.map(({ id }) => id);
  const componentMachinesOptions = variantMachines
    .filter(({ id, isDeleted }) => componentMachineIds.includes(id) || !isDeleted)
    .map(({ id, name, isActive, isDeleted, dashboardType }) => {
      if (isDeleted) return { id, name: `${name} (${t('labels.deleted', { context: 'workstation' })})`, dashboardType };

      if (!isActive)
        return { id, name: `${name} (${t('labels.inactive', { context: 'workstation' })})`, dashboardType };

      return { id, name, dashboardType };
    });
  const selectedMachineNames = componentMachinesOptions
    .filter(({ id }) => componentMachineIds.includes(id))
    .map(({ name }) => name);

  return (
    <Stack>
      <FieldArrayItem onRemove={onRemoveClick} disableRemove={!showRemoveButton} showRemoveButton={showRemoveButton}>
        <Box
          sx={{
            height: '100%',
            paddingTop: 1
          }}
        >
          <IconButton onClick={() => setIsExpanded((prev) => !prev)}>
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
        <Stack spacing={4} flexGrow={1}>
          <Stack direction='row' spacing={2}>
            <Controller
              name={`components.${index}.variantId`}
              control={control}
              defaultValue={component.variantId}
              render={({ field, fieldState }) => (
                <SelectVirtualized
                  {...field}
                  fullWidth
                  options={variantWithMachinesOptions}
                  label={t('labels.component')}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  onChange={(value) => {
                    field.onChange({ target: { value: value ?? '', name: field.name } });
                    setValue(`components.${index}.machines`, []);
                    trigger(`components`).then(() => {
                      clearErrors(`components.${index}.machines`);
                    });
                  }}
                />
              )}
            />
            <Box>
              <FormManualNumericControl
                name={`components.${index}.amount`}
                minNumber={1}
                maxNumber={31556926}
                height='52px'
              />
            </Box>
          </Stack>
          {isExpanded && (
            <FormMultiSelectVirtualized
              name={`components.${index}.machines`}
              options={componentMachinesOptions}
              label={t('labels.workstations')}
              disabled={!variantId}
              helperText={t('messages.thisInformationWillBeUsedToEstimateTheOrderProcessingTime')}
            />
          )}
        </Stack>
      </FieldArrayItem>
      {!isExpanded && (
        <>
          {selectedMachineNames.length ? (
            <Typography variant='caption'>{selectedMachineNames.join(', ')}</Typography>
          ) : (
            <Typography variant='caption' color='error'>
              {t('messages.noSelectedWorkstations')} {t('messages.selectAtLeastX', { min: 1 })}
            </Typography>
          )}
        </>
      )}
    </Stack>
  );
};
