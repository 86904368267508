const paths = {
  root: '/',
  rfidLogin: '/rfid-login',
  login: '/login',
  home: '/home',
  appSelection: '/app-selection',
  installedApp: '/installed-app',
  languageSelection: '/language-selection',
  forgotPassword: '/forgot-password',
  signinCallback: '/signin-callback',
  signoutCallback: '/signout-callback',
  setMachine: '/setMachine',
  notFound: '/notFound',
  machineRemoved: '/machineRemoved',
  machineDeactivated: '/machineDeactivated',
  machineAlreadyPaired: '/machineAlreadyPaired',
  unauthorized: '/unauthorized',
  forbidden: '/forbidden',
  activateAccount: '/account/activate',
  activationLinkExpired: '/activation-link-expired',
  activationLinkIncorrect: '/activation-link-incorrect',
  resetPassword: '/account/resetPassword',
  resetPasswordByOldPassword: '/Pages/Auth/ResetPasswordByOldPassword',
  passwordResetLinkExpired: '/password-reset-link-expired',
  passwordResetLinkIncorrect: '/password-reset-link-incorrect',
  accessDenied: '/access-denied',
  workstationDisconnected: '/workstation-disconnected',
  // Operator
  operatorPairWithMachine: '/operator/pair-with-machine',
  operatorLandingPage: '/operator/landing-page',
  operatorRetool: '/operator/retool',
  operatorReportFailureReason: '/operator/report-failure-reason',
  operatorReportIdleReason: '/operator/report-idle-reason',
  operatorEnforcedReport: '/operator/enforced-report',
  operatorDashboard: '/operator/dashboard',
  operatorFailureCheckList: '/operator/failure-check-list',
  operatorFiles: '/operator/files',
  operatorNotifications: '/operator/notifications',
  operatorReportScrapReason: '/operator/report-scrap-reason',
  operatorReportManualScrapReason: '/operator/report-manual-scrap-reason',
  operatorReportComponentScrapReason: '/operator/report-component-scrap-reason',
  operatorReportSuspendedRetoolReason: '/operator/report-suspended-retool-reason',
  operatorReportReworkReason: '/operator/report-rework-reason',
  operatorClassifyInvalidBunch: '/operator/classify-invalid-bunch',
  operatorClassifyPotentialFailureAsFailure: '/operator/classify-potential-failure-as-failure',
  operatorClassifyPotentialFailureAsFailureReason: '/operator/classify-potential-failure-as-failure-reason',
  operatorClassifyPotentialFailureAsIdleReason: '/operator/classify-potential-failure-as-idle-reason',
  operatorProductionFlow: '/operator/production-flow',
  operatorMultiOperator: '/operator/multi-operator',
  operatorWorkstationHistory: '/operator/workstation-history',
  operatorCallForeman: '/operator/call-foreman',
  operatorSettings: '/operator/settings',
  operatorCharts: '/operator/charts',
  operatorFinishOrder: '/operator/finish-order',
  operatorReportIncompleteOrderReason: '/operator/report-incomplete-order-reason',
  operatorReportIncompleteVariantRetoolReason: '/operator/report-incomplete-variant-retool-reason',
  // Foreman
  foremanDashboard: '/foreman/dashboard',
  foremanMachinesDashboard: '/foreman/machines-dashboard',
  foremanOrdersDashboard: '/foreman/orders-dashboard',
  foremanNotifications: '/foreman/notifications',
  foremanProductionFlow: '/foreman/production-flow',
  foremanScrapsClassification: '/foreman/scraps-classification',
  foremanComponentScrapsClassification: '/foreman/component-scraps-classification',
  foremanLineStatus: '/foreman/lineStatus',
  foremanKanban: '/foreman/kanban',
  foremanIdleReasons: '/foreman/idle-reasons',
  foremanRetools: '/foreman/retools',
  foremanFailureReasons: '/foreman/failure-reasons',
  foremanWorkWithFaiilureReasons: '/foreman/work-with-failure-reasons',
  foremanPDCA: '/foreman/pdca',
  foremanFiles: '/foreman/files',
  foremanOrders: '/foreman/orders',
  foremanProductivity: '/foreman/productivity',
  foremanPerformanceDrops: '/foreman/performance-drops',
  foremanYamazumi: '/foreman/yamazumi',
  foremanStatusesTimelineChart: '/foreman/statuses-timeline-chart',
  foremanOeeChart: '/foreman/oee-chart',
  foremanOutput: '/foreman/output',
  foremanReworks: '/foreman/reworks',
  foremanOperatorsWorkData: '/foreman/operators-work-data',
  // Technologist
  technologistNotifications: '/technologist/notifications',
  technologistWorkstations: '/technologist/workstations',
  technologistLines: '/technologist/lines',
  technologistVariants: '/technologist/variants',
  technologistTechnologyEditor: '/technologist/technology-editor',
  technologistParameters: '/technologist/parameters',
  // Admin
  adminCatalogs: '/admin/catalogs',
  adminDataLoggers: '/admin/data-loggers',
  adminShiftEditor: '/admin/shift-editor',
  adminNotifications: '/admin/notifications',
  adminNotificationManager: '/admin/notification-manager',
  adminUsers: '/admin/users',
  adminRoles: '/admin/roles',
  adminTranslations: '/admin/translations',
  adminPairedPanels: '/admin/paired-panels',
  // Maintenance
  maintenanceDashboard: '/maintenance/dashboard',
  maintenanceRepairTasksAssignment: '/maintenance/repair-tasks-assignment',
  maintenanceMaintenanceTasksAssignment: '/maintenance/maintenance-tasks-assignment',
  maintenanceLineLayout: '/maintenance/line-layout',
  maintenancePdca: '/maintenance/pdca',
  maintenanceLineStatus: '/maintenance/line-status',
  maintenanceFiles: '/maintenance/files',
  maintenanceCheckLists: '/maintenance/check-lists',
  maintenanceNotifications: '/maintenance/notifications',
  maintenanceMaintenancePlanner: '/maintenance/maintenance-planner',
  // Mechanic
  mechanicDashboard: '/mechanic/dashboard',
  mechanicFailureAssignment: '/mechanic/failure-assignment',
  mechanicTaskDetails: '/mechanic-task-details',
  reportUnsuccessfulRepairReason: '/reportUnsuccessfulRepairReason',
  reportUnsuccessfulMaintenanceReason: '/reportUnsuccessfulMaintenanceReason',
  reportSuccessfulReason: '/reportSuccessfulReason',
  reportStopReason: '/reportStopReason',
  // Planner
  plannerNotifications: '/planner/notifications',
  plannerOrders: '/planner/orders'
};

export default paths;
