export const LocalStorageKeys = {
  CLIENT_ID: 'clientId',
  LOGIN_PREFERENCE: 'loginPreference',
  REDIRECT_PATH: 'redirectPath',
  SELECTED_MACHINE: 'selectedMachine',
  SELECTED_LANGUAGE_CODE: 'selectedLanguageCode.v2',
  PLANNER_ORDERS_FILTERS: 'plannerOrdersFilters',
  FOREMAN_LINE_STATUS_FILTERS: 'foremanLineStatusFilters',
  FOREMAN_DASHBOARD_LINE_STATUS_FILTERS: 'foremanDashboardLineStatusFilters.v2',
  MAINTENANCE_DASHBOARD_LINE_STATUS_FILTERS: 'maintenanceDashboardLineStatusFilters.v2',
  MAINTENANCE_LINE_STATUS_FILTERS: 'maintenanceLineStatusFilters',
  FOREMAN_FILES_FILTERS: 'foremanFilesFilters.v2',
  FOREMAN_IDLE_REASONS_FILTERS: 'foremanIdleReasonsFilters',
  FOREMAN_IDLE_REASONS_VIEW_MODE: 'foremanIdleReasonsViewMode',
  FOREMAN_FAILURE_REASONS_FILTERS: 'foremanFailureReasonsFilters',
  FOREMAN_RETOOLS_FILTERS: 'foremanRetoolsFilters',
  FOREMAN_RETOOLS_VIEW_MODE: 'foremanRetoolsViewMode',
  FOREMAN_WORK_WITH_FAILURE_REASONS_FILTERS: 'foremanWorkWithFailureReasonsFilters',
  FOREMAN_STATUSES_TIMELINE_FILTERS: 'foremanStatusesTimelineFilters',
  FOREMAN_STATUSES_DURATION_PIVOT_GRID: 'foremanStatusesDurationPivotGrid',
  FOREMAN_STATUSES_DURATION_DROPDOWNS: 'foremanStatusesDurationDropdowns',
  FOREMAN_STATUSES_DURATION_DATA_GRID: 'foremanStatusesDurationDataGrid',
  FOREMAN_PERFORMANCE_DROPS_FILTERS: 'foremanPerformanceDropsFilters',
  FOREMAN_ORDERS_FILTERS: 'foremanOrdersFilters',
  OPERATOR_WORKSTATION_HISTORY_FILTERS: 'operatorWorkstationHistoryFilters',
  FOREMAN_PRODUCTION_PLAN_FILTERS: 'foremanProductionPlanFilters',
  FOREMAN_PRODUCTION_PLAN_PAGE_STATE: 'foremanProductionPlanPageState',
  FOREMAN_PRODUCTION_PLAN_TIMELINE_DROPDOWNS: 'foremanProductionPlanTimelineDropdowns',
  FOREMAN_PRODUCTION_PLAN_INTERVALS_DROPDOWNS: 'foremanProductionPlanIntervalsDropdowns',
  FOREMAN_PRODUCTION_PLAN_TIMELINE_PIVOT_GRID: 'foremanProductionPlanTimelinePivotGrid',
  FOREMAN_PRODUCTION_PLAN_INTERVALS_PIVOT_GRID: 'foremanProductionPlanIntervalsPivotGrid',
  FOREMAN_REWORKS_PAGE_STATE: 'foremanReworksPageState',
  FOREMAN_REWORKS_TIMELINE_DROPDOWNS: 'foremanReworksTimelineDropdowns',
  FOREMAN_REWORKS_TIMELINE_PIVOT_GRID: 'foremanReworksTimelinePivotGrid',
  FOREMAN_REWORKS_REASONS_DROPDOWNS: 'foremanReworksReasonsDropdowns',
  FOREMAN_REWORKS_REASONS_PIVOT_GRID: 'foremanReworksReasonsPivotGrid',
  FOREMAN_REWORKS_REASONS_DATA_GRID: 'foremanReworksReasonsDataGrid',
  FOREMAN_REWORKS_DURATION_DROPDOWNS: 'foremanReworksDurationDropdowns',
  FOREMAN_REWORKS_DURATION_PIVOT_GRID: 'foremanReworksDurationPivotGrid',
  FOREMAN_REWORKS_DURATION_DATA_GRID: 'foremanReworksDurationDataGrid',
  FOREMAN_OEE_FILTERS: 'foremanOeeFilters',
  FOREMAN_PRODUCTIVITY_FILTERS: 'foremanProductivityFilters',
  FOREMAN_PRODUCTIVITY_PIVOT_GRID: 'foremanProductivityPivotGrid',
  FOREMAN_PRODUCTIVITY_DROPDOWNS: 'foremanProductivityDropdowns',
  FOREMAN_REWORKS_FILTERS: 'foremanReworksFilters',
  FOREMAN_COMPONENT_SCRAPS_CLASSIFICATION_FILTERS: 'foremanComponentScrapsClassificationFilters',
  FOREMAN_SCRAPS_CLASSIFICATION_FILTERS: 'foremanScrapsClassificationFilters',
  FOREMAN_OUTPUT_FILTERS: 'foremanOutputFilters',
  FOREMAN_YAMAZUMI_FILTERS: 'foremanYamazumiFilters',
  FOREMAN_PDCA_SELECTED_LINE: 'foremanPdcaSelectedLineId',
  FOREMAN_PDCA_VIEW_TYPE: 'foremanPdcaViewType',
  FOREMAN_PDCA_EXPANDED_TASKS: 'foremanPdcaExpandedTasks',
  FOREMAN_DASHBOARD_SCRAPS_CLASSIFICATION_FILTERS: 'foremanDashboardScrapsClassificationFilters',
  FOREMAN_DASHBOARD_PRODUCTION_PLAN_FILTERS: 'foremanDashboardProductionPlanFilters',
  FOREMAN_DASHBOARD_SELECTED_WORKSTATION_ID: 'foremanDashboardSelectedWorkstationId',
  FOREMAN_DASHBOARD_PDCA_VIEW_TYPE: 'foremanDashboardPdcaViewType',
  FOREMAN_DASHBOARD_PDCA_EXPANDED_TASKS: 'foremanDashboardPdcaExpandedTasks',
  FOREMAN_DASHBOARD_ORDERS_FILTERS: 'foremanDashboardOrdersFilters',
  FOREMAN_OPERATORS_WORK_DATA_FILTERS: 'foremanOperatorsWorkDataFilters',
  MAINTENANCE_PDCA_VIEW_TYPE: 'maintenancePdcaViewType',
  MAINTENANCE_PDCA_EXPANDED_TASKS: 'maintenancePdcaExpandedTasks',
  MAINTENANCE_BOARD_PDCA_VIEW_TYPE: 'maintenanceBoardPdcaViewType',
  MAINTENANCE_BOARD_PDCA_EXPANDED_TASKS: 'maintenanceBoardPdcaExpandedTasks'
};
