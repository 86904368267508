import { differenceInSeconds } from 'date-fns';

export const getTimeFromLastRetoolInSeconds = (lastRetoolDate: string) => {
  return differenceInSeconds(new Date(), new Date(lastRetoolDate));
};

export const calculateOrderActualGoal = (
  secondsFromLastRetool: number,
  cycleInSeconds: number,
  defaultUnitProducedCount: number
) => {
  return Math.floor(secondsFromLastRetool / cycleInSeconds) * defaultUnitProducedCount;
};

export const calculatePacedGoal = (goal: number, pace: number) => {
  return Math.floor((goal * pace) / 100);
};
